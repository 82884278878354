// src/App.js
import React from 'react';
import Header from './components/Header';
import MainContent from './components/MainContent';
import './App.css';

function App() {
  // Placeholder data for cards - you should replace this with your actual card data
  const cards = [
    {
      id: 1,
      title: 'Phantom Force',
      description: 'A stealthy encounter with spirits',
      imageUrl: '/path/to/phantom-force.png'
    },
    {
      id: 2,
      title: 'Ghoul Maze',
      description: 'A labyrinthine challenge with undead',
      imageUrl: '/path/to/ghoul-maze.png'
    },
    // ... more cards
  ];

  return (
    <div className="App">
      <head>
        <style>
          @import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600&family=Special+Elite&display=swap');
        </style>
      </head>
      <Header />
      <MainContent cards={cards} />
    </div>
  );
}

export default App;
