// src/components/Header.js
import React from 'react';
import './Header.css';
import logo from '../assets/logo.png';

function Header() {
  return (
    <div className="header">
    <header className="header-content">
      <img src={logo} alt="DM-Toolkit" className="logo"/>
      <nav className="navigation">
        {/* Example navigation items */}
        <a href="#entities">GPTS</a>
        <a href="#den">ABOUT</a>
        <a href="#strong">CONTACT</a>
      </nav>
    </header>
    </div>
  );
}

export default Header;
