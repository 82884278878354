// src/components/MainContent.js
import React from 'react';
import './MainContent.css';
import compassImage from '../assets/compasssmol.jpg';
import cardImage1 from '../assets/Card1.png';
import cardImage2 from '../assets/Card2.png';
import cardImage3 from '../assets/Card3.png';
import cardImage4 from '../assets/Card4.png';


function MainContent() {
  return (
    <main className="main-content">
      <div className="compass-container">
        <img src={compassImage} alt="Compass" className="compass-image"/>
      </div>
      <div className="card-container">
        <div className="card-body">
            <h1>Welcome to DM Toolkit!</h1>
            <h2>We use AI to supercharge your Dungeon Master abilities!</h2>
            <hr></hr>
            <div>
                <p>Check out our current GPTs below:</p>
                <hr></hr>
            </div>
            <section className="card-holder">
              <div className="card-div">
                <a href='https://chat.openai.com/g/g-AsuigQYkp-npc-maker'>
                  <img src={cardImage3} alt="Compass" className="card-image"/>
                <div>
                  <button className='button' href='https://chat.openai.com/g/g-AsuigQYkp-npc-maker'>Try It</button>
                </div>
                </a>
              </div>
              <div className="card-div">
                <a href='https://chat.openai.com/g/g-AsuigQYkp-npc-maker'>
                  <img src={cardImage2} alt="Compass" className="card-image"/>
                <div>
                  <button className='button'>Try It</button>
                </div>
                </a>
              </div>
              <div className="card-div">
                <a href='https://chat.openai.com/g/g-84gSTYyjB-monster-maker'>
                  <img src={cardImage1} alt="Compass" className="card-image"/>
                <div>
                  <button className='button'>Try It</button>
                </div>
                </a>
              </div>
              <div className="card-div">
                <a href='https://chat.openai.com/g/g-WdyEzqehe-dice-master'>
                  <img src={cardImage4} alt="Compass" className="card-image"/>
                <div>
                  <button className='button'>Try It</button>
                </div>
                </a>
              </div>
            </section>
        </div>
      </div>
      <p className="description">
        Copyright DMToolkit 2023. Site created by ChatGPT
      </p>
    </main>
  );
}

export default MainContent;
